<template>
  <div class="form-elements">
    <div class="va-row center">
      <div class="flex md6">
        <vuestic-widget>
          <form @submit.prevent="mudarSenha">
            <div class="flex md12">
              <div class="form-group">
                <div class="input-group">
                  <input
                    id="simple-input"
                    type="password"
                    required
                    v-model="novaSenha.old_password"
                  >
                  <label
                    class="control-label"
                    for="simple-input"
                  >Senha Antiga</label>
                  <span
                    class="alert-danger"
                    v-if="erros['old_password']"
                  >{{erros['old_password'][0]}}</span>
                </div>
              </div>
              <div class="form-group">
                <div class="input-group">
                  <input
                    :class="{is_valid : valido}"
                    id="simple-input"
                    type="password"
                    required="required"
                    v-model="novaSenha.new_password1"
                    @keyup="validando"
                  >
                  <label
                    class="control-label"
                    for="simple-input"
                  >Nova Senha</label>
                  <span
                    class="alert-danger"
                    v-if="erros['new_password1']"
                  >{{erros['new_password1'][0]}}</span>
                </div>
              </div>
              <div class="form-group">
                <div class="input-group">
                  <input
                    :class="{is_valid : valido, not_valid: isvalid}"
                    id="simple-input"
                    type="password"
                    required="required"
                    v-model="novaSenha.new_password2"
                    @keyup="validando"
                  >
                  <label
                    class="control-label"
                    for="simple-input"
                  >Confirma Senha</label>
                  <span
                    class="alert-danger"
                    v-if="erros['new_password2']"
                  >{{erros['new_password2'][0]}}</span>
                </div>
              </div>
            </div>
            <div class="butoes">
              <button
                type="submit"
                class="btn btn-pale btn-micro"
                @click.prevent="voltar()"
              >Voltar</button>
              <input
                type="submit"
                class="btn btn-info btn-micro"
                @click.prevent.stop="mudarSenha()"
                value="Altera senha"
              >
            </div>
          </form>
        </vuestic-widget>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line
import { error } from 'util';

export default {
  data() {
    return {
      novaSenha: {
        old_password: '',
        new_password1: '',
        new_password2: '',
      },
      erros: [],
      valido: false,
      isvalid: false,
    };
  },
  computed: {
    usuario() {
      return JSON.parse(localStorage.user);
    },
  },
  created() {
    this.validando();
  },
  methods: {
    mudarSenha() {
      this.$axios.post('/rest-auth/password/change/', this.novaSenha).then((res) => {
        this.situacao = res.data;
      }).then(() => {
        this.$swal({
          toast: true,
          position: 'top-end',
          showCancelButton: false,
          type: 'success',
          title: 'Senha alterada com Sucesso!',
          showConfirmButton: false,
          timer: 3000,
        });
        this.$router.push('/dashboard');
      })
        .catch((err) => {
          this.erros = err.response.data;
          this.$swal({
            toast: true,
            position: 'top-end',
            showCancelButton: false,
            type: 'error',
            title: 'Ocorreu algum erro!',
            showConfirmButton: false,
            timer: 3000,
          });
        });
    },
    validando() {
      if (!this.novaSenha.new_password1) {
        this.valido = false;
      } else if (!this.novaSenha.new_password2) {
        this.valido = false;
      } else if (this.novaSenha.new_password1 === this.novaSenha.new_password2) {
        this.isvalid = false;
        this.valido = true;
      } else if (this.novaSenha.new_password1 !== this.novaSenha.new_password2) {
        this.isvalid = true;
        this.valido = false;
      } else if (!this.novaSenha.new_password1) {
        this.isvalid = false;
      }
    },
    logout() {
      localStorage.removeItem('token');
      window.location = '/login';
    },
    voltar() {
      window.location = 'dashboard';
    },
  },
};
</script>
<style  lang="scss">

.is_valid {
  color: green !important;
  border-color: green !important;
  &::before {
    content: '';
    background: green !important;
  }
}
.not_valid {
  border-color: red !important;
  &::before {
    content: '';
    background: red !important;
  }
}
.center {
  justify-content: center;
  margin-top: 10%;
}
.butoes {
  display: flex;
  justify-content: flex-end;
}
</style>
